export const sendQuoteEmail = async (clientDetails, quoteDetails) => {
  try {
    // Validate required fields
    if (!clientDetails || !quoteDetails) {
      throw new Error('Missing required fields: clientDetails and quoteDetails');
    }

    if (!clientDetails.name || !clientDetails.email) {
      throw new Error('Missing required client details: name and email');
    }

    if (!quoteDetails.type) {
      throw new Error('Missing quote type');
    }

    // Log the email sending process
    console.log('Sending quote email:', {
      type: quoteDetails.type,
      clientName: clientDetails.name,
      clientEmail: clientDetails.email,
      totalCost: quoteDetails.totalWithVAT
    });

    // Send the quote email
    const response = await fetch('/.netlify/functions/send-quote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clientDetails,
        quoteDetails
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to send quote email');
    }

    const data = await response.json();
    console.log('Quote email sent successfully:', data);
    return data;
  } catch (error) {
    console.error('Error sending quote email:', {
      error: error.message,
      clientDetails,
      quoteDetails: {
        type: quoteDetails?.type,
        totalCost: quoteDetails?.totalWithVAT
      }
    });
    throw error;
  }
}; 